<template>
	<v-row>
		<v-dialog v-model="dialog" max-width="500">
			<v-card>
				<v-card-title class="text-h5">Confirmar emissão do parcelamento</v-card-title>
				<v-divider />
				<v-card-text class="pt-5">
					<p class="text-h6">Deseja confirmar a emissão do parcelamento?</p>
					<p
						class="text-body-1"
					>Após a confirmação do título qualquer estorno deverá ser feito pelo CISS!</p>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialog = false"
					>Cancelar</v-btn>
					<v-btn
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="emitirParcelamento()"
					>Parcelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogLoading" hide-overlay persistent width="350">
			<v-card color="primary" dark>
				<v-card-text class="pt-3">
					Aguarde enquanto o parcelamento é gerado!
					<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-col cols="12" md="8">
			<v-card>
				<v-card-title>Selecione os títulos</v-card-title>
				<v-divider />
				<v-card-text>
					<v-card v-if="dados.length > 0" flat dark color="primary" class="mb-2">
						<v-card-text>
							<v-row class="white--text">
								<v-col cols="auto">
									<strong>Filial:</strong>
									{{dados[0].idfilial}} - {{dados[0].filial}}
								</v-col>
								<v-col>
									<strong>Cliente:</strong>
									{{dados[0].idcliente}} - {{dados[0].cliente}}
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-simple-table style="border: 1px solid #9E9E9E">
						<thead>
							<tr>
								<th class="px-2 text-center"></th>
								<th class="px-2 text-center">Título</th>
								<th class="px-2 text-center">Digito</th>
								<th class="px-2 text-center">Data de movimento</th>
								<th class="px-2 text-center">Data de vencimento</th>
								<th class="px-2 text-center">Valor do título</th>
								<th class="px-2 text-center">Valor juros</th>
								<th class="px-2 text-center">Valor total</th>
								<th class="px-2 text-center">Forma de pagamento</th>
							</tr>
						</thead>
						<tbody v-if="dados.length > 0">
							<tr v-for="(u,i) in dados" :key="i">
								<td class="pl-2 pr-0 text-center">
									<v-checkbox v-if="u" dense class="ma-0 mt-3" v-model="notas" :value="u"></v-checkbox>
								</td>
								<td class="px-1 text-center">{{u.titulo}}</td>
								<td class="px-1 text-center">{{u.digito}}</td>
								<td class="px-1 text-center">{{u.dtmovimento}}</td>
								<td class="px-1 text-center">{{u.dtvencimento}}</td>
								<td class="px-1 text-truncate">{{u.saldotitulo | formataDinheiro}}</td>
								<td class="px-1 text-truncate">{{u.jurostitulo | formataDinheiro}}</td>
								<td class="px-1 text-truncate">{{u.valorliqtitulo | formataDinheiro}}</td>
								<td class="px-1 text-center">{{u.idrecebimento}} - {{u.recebimento}}</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td colspan="9" class="text-center">Nenhum titulo encontrado</td>
							</tr>
						</tbody>
						<tfoot>
							<tr class="font-weight-bold primary white--text">
								<td class="pl-2 pr-0 text-center" colspan="5">Total selecionado</td>
								<td
									class="px-1 text-truncate"
									v-if="notas.length > 0"
								>{{notas.map(v => v.saldotitulo).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td class="px-1 text-truncate" v-else>R$ 0,00</td>
								<td
									class="px-1 text-truncate"
									v-if="notas.length > 0"
								>{{notas.map(v => v.jurostitulo).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td class="px-1 text-truncate" v-else>R$ 0,00</td>
								<td
									class="px-1 text-truncate"
									v-if="notas.length > 0"
								>{{notas.map(v => v.valorliqtitulo).reduce((a,v) => a+v) | formataDinheiro}}</td>
								<td class="px-1 text-truncate" v-else>R$ 0,00</td>
								<td class="px-1 text-center"></td>
							</tr>
						</tfoot>
					</v-simple-table>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12" md="4">
			<v-card>
				<v-card-text>
					<v-stepper v-model="step" vertical flat>
						<v-stepper-step
							:complete="step > 1"
							step="1"
							class="px-0 py-3 font-weight-bold"
						>Buscar cliente</v-stepper-step>
						<v-stepper-content step="1" class="px-2 mx-0 font-weight-bold">
							<v-row align="end">
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Cliente</div>
									<v-autocomplete
										no-filter
										v-model="parcelamento.idcliente"
										outlined
										dense
										:search-input.sync="search"
										:items="items"
										:loading="carregando"
										item-text="nome"
										item-value="idcliente"
										class="mb-n10"
										@change="listarNotas()"
									></v-autocomplete>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn :disabled="notas.length <= 0" small color="primary" @click="step = 2">Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 2"
							step="2"
							class="px-0 py-3 font-weight-bold"
						>Condições de parcelamento</v-stepper-step>
						<v-stepper-content step="2" class="px-2 mx-0 font-weight-bold">
							<v-row align="end">
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Número de parcelas</div>
									<v-select
										v-if="notas.length > 0"
										class="mb-n10"
										v-model="parcelamento.parcelas"
										:items="indices.map(v => {return {...v, parcela: `${v.parcelas} x R$ ${valorParcelaFiltro(v.parcelas).toFixed(2)}`}})"
										item-text="parcela"
										item-value="value"
										outlined
										dense
									></v-select>
									<v-select disabled outlined dense v-else class="mb-n10" />
								</v-col>
								<v-col cols="12">
									<div class="text-body-1 font-weight-bold">Primeiro vencimento</div>
									<v-select
										class="mb-n10"
										v-model="parcelamento.vencimento"
										:items="vencimento"
										item-value="id"
										item-text="valor"
										outlined
										dense
									></v-select>
								</v-col>
								<v-col cols="12" class="text-right">
									<v-btn small text color="primary" @click="step = 1">Voltar</v-btn>
									<v-btn
										:disabled="notas.length <= 0 || parcelamento.parcelas == 0 || parcelamento.vencimento == 0"
										small
										color="primary"
										@click="step = 3"
									>Próximo</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
						<v-stepper-step
							:complete="step > 3"
							step="3"
							class="px-0 py-3 font-weight-bold"
						>Confirmação do parcelamento</v-stepper-step>
						<v-stepper-content step="3" class="px-0 mx-0">
							<v-row align="end">
								<v-col cols="12" v-if="dados.length > 0 && notas.length > 0">
									<div class="font-weight-bold text-truncate pt-2">Cliente</div>
									<div class="text-truncate">{{dados[0].cliente}}</div>
									<div class="font-weight-bold text-truncate pt-2">Quantidade de parcelas</div>
									<div class="text-truncate">
										{{indices.filter(v => v.value == parcelamento.parcelas).map(v => v.parcelas)[0]}} x
										{{valorParcela() | formataDinheiro}}
									</div>
									<div class="font-weight-bold text-truncate pt-2">Juros do parcelamento</div>
									<div class="text-truncate">{{valorJuros() | formataDinheiro}}</div>
									<div class="font-weight-bold text-truncate pt-2">Valor final</div>
									<div class="text-truncate">{{valorTotal() | formataDinheiro}}</div>
									<div class="font-weight-bold text-truncate pt-2">Primeiro vencimento</div>
									<div
										class="text-truncate"
									>{{`${new Date(new Date().setHours(+(parcelamento.vencimento * 24))).toLocaleDateString("pt-BR")}`}}</div>
								</v-col>
								<v-col cols="12">
									<v-btn small text block color="primary" class="mb-1" @click="step = 2">Voltar</v-btn>
									<v-btn
										:disabled="notas.length <= 0"
										:loading="carregando"
										color="primary"
										block
										small
										elevation="0"
										@click="dialog = true"
									>Finalizar</v-btn>
								</v-col>
							</v-row>
						</v-stepper-content>
					</v-stepper>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ParcelamentoEmitir",
	components: { CardDialog },
	data: () => ({
		step: 1,
		carregando: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dialogLoading: false,
		dialog: false,
		dados: [],
		notas: [],
		indices: [],
		vencimento: [
			{
				id: 5,
				valor: `${new Date(
					new Date().setHours(+120)
				).toLocaleDateString("pt-BR")}`,
			},
			{
				id: 10,
				valor: `${new Date(
					new Date().setHours(+240)
				).toLocaleDateString("pt-BR")}`,
			},
			{
				id: 15,
				valor: `${new Date(
					new Date().setHours(+360)
				).toLocaleDateString("pt-BR")}`,
			},
			{
				id: 30,
				valor: `${new Date(
					new Date().setHours(+720)
				).toLocaleDateString("pt-BR")}`,
			},
		],
		parcelamento: {
			idcliente: 0,
			parcelas: 0,
			vencimento: 0,
		},
		aguardarBusca: "",
		nomeLimit: 50,
		clientes: [],
		search: null,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "timeLimit", "usuario"]),
		items() {
			if (this.clientes == null) return;

			this.clientes.forEach((cliente) => {
				if (cliente.nome.length > this.nomeLimit) {
					cliente.nome =
						cliente.nome.slice(0, this.nomeLimit) + "...";
				}
			});

			return this.clientes;
		},
	},
	methods: {
		carregarClientes(val) {
			clearTimeout(this.aguardarBusca);
			this.aguardarBusca = setTimeout(() => {
				this.carregando = true;
				return axios
					.post(`${this.backendUrl}cliente/listar`, {
						limit: 30,
						offset: 0,
						busca: val || null,
					})
					.then((res) => {
						this.clientes = res.data.lista;
						this.carregando = false;
					})
					.catch(() => {
						this.carregando = false;
					});
			}, this.timeLimit);
		},
		listarNotas() {
			if (
				this.parcelamento.idcliente &&
				this.parcelamento.idcliente == 0
			) {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/listar`, {
					idcliente: this.parcelamento.idcliente,
				})
				.then((res) => {
					this.notas = [];
					if (res.data) {
						this.dados = res.data;
					} else {
						this.dados = [];
					}
					this.carregando = false;
					this.listarIndices();
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarIndices() {
			if (
				this.parcelamento.idcliente &&
				this.parcelamento.idcliente == 0
			) {
				return;
			}
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/juros/listar`, {
					idcliente: this.parcelamento.idcliente,
				})
				.then((res) => {
					if (res.data) {
						this.indices = res.data.map((v) => {
							return {
								...v,
								value: `${v.idrecebimento}_${v.flagjuros}_${v.parcelas}_${v.grupo}`,
							};
						});
					} else {
						this.indices = [];
					}
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		emitirParcelamento() {
			this.dialog = false;
			if (this.notas.length <= 0) {
				return;
			}
			this.carregando = true;
			this.dialogLoading = true;
			return axios
				.post(`${this.backendUrl}parcelamento/emitir`, {
					titulos: this.notas,
					vencimento: this.parcelamento.vencimento,
					parcelas: this.parcelamento.parcelas,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogLoading = false;
					if (res.data) {
						this.$router.push({
							name: "ParcelamentoBoleto",
							params: {
								idcliente: res.data.idcliente,
								titulo: res.data.titulo,
							},
						});
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg = res.data.erro;
						} else {
							this.dialogErro.msg =
								"Não foi possível realizar o parcelamento dos títulos";
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogLoading = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Não foi possível realizar o parcelamento dos títulos";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		valorParcelaFiltro(qtdParcela) {
			let parcela;
			let juros;
			juros =
				this.notas
					.map((v) => v.valorliqtitulo)
					.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.parcelas == qtdParcela)
						.map((v) => v.indice)[0] *
					this.indices
						.filter((v) => v.parcelas == qtdParcela)
						.map((v) => v.parcelas)[0] -
				this.notas.map((v) => v.valorliqtitulo).reduce((a, v) => a + v);
			if (juros >= 0) {
				parcela =
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.parcelas == qtdParcela)
						.map((v) => v.indice)[0];
			} else {
				parcela =
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
						this.indices
							.filter((v) => v.parcelas == qtdParcela)
							.map((v) => v.indice)[0] -
					juros /
						this.indices
							.filter((v) => v.parcelas == qtdParcela)
							.map((v) => v.parcelas)[0];
			}
			return parcela;
		},
		valorParcela() {
			let parcela;
			let juros;
			juros =
				this.notas
					.map((v) => v.valorliqtitulo)
					.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.indice)[0] *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.parcelas)[0] -
				this.notas.map((v) => v.valorliqtitulo).reduce((a, v) => a + v);
			if (juros >= 0) {
				parcela =
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.indice)[0];
			} else {
				parcela =
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
						this.indices
							.filter(
								(v) => v.value == this.parcelamento.parcelas
							)
							.map((v) => v.indice)[0] -
					juros /
						this.indices
							.filter(
								(v) => v.value == this.parcelamento.parcelas
							)
							.map((v) => v.parcelas)[0];
			}
			return parcela;
		},
		valorJuros() {
			let juros;
			juros =
				(
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.indice)[0]
				).toFixed(2) *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.parcelas)[0] -
				this.notas.map((v) => v.valorliqtitulo).reduce((a, v) => a + v);
			if (juros < 0.5) {
				juros = 0;
			}
			return juros;
		},
		valorTotal() {
			let total;
			total =
				(
					this.notas
						.map((v) => v.valorliqtitulo)
						.reduce((a, v) => a + v) *
					this.indices
						.filter((v) => v.value == this.parcelamento.parcelas)
						.map((v) => v.indice)[0]
				).toFixed(2) *
				this.indices
					.filter((v) => v.value == this.parcelamento.parcelas)
					.map((v) => v.parcelas)[0];
			if (
				total <
				this.notas
					.map((v) => v.valorliqtitulo)
					.reduce((a, v) => a + v) +
					0.05
			) {
				total = this.notas
					.map((v) => v.valorliqtitulo)
					.reduce((a, v) => a + v);
			}
			return total;
		},
		init() {},
	},
	watch: {
		search: function (val) {
			this.carregarClientes(val);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>